$(document).ready(function() {

    // Récupérer l'élément contenant les données
    var form = document.getElementById('frmEditBs');
    var typeBS = 'Usual';

    if(form){
        typeBS = form.getAttribute('data-typeBS');
    }

    if(typeBS == 'RDV'){
        clientId = form.getAttribute('data-clientId');

        $('#frmEditBs').formValidation({
            framework: "uikit",
            trigger: 'blur',
            button: {
                selector: '#btnSubmit',
                disabled: 'disabled'
            },
            fields: {
                rdv_date: {
                    trigger: 'blur change',
                    validators: {
                        // date : {
                        //     format: "DD/MM/YYYY",
                        //     min: moment().add(1, 'days').format('DD/MM/YYYY'),
                        //     message: "La date ne peut-être antérieur ou égale à la date du jour"
                        // },
                        callback: {
                            callback: function(value, validator, $field) {
                                // Vérifie si la date choisie est un dimanche
                                var selectedDate = moment(value, 'DD/MM/YYYY');
                                return selectedDate.day() !== 0; // 0 correspond à dimanche
                            },
                            message: "La date choisie ne peut pas être un dimanche"
                        },
                        notEmpty: {
                            message: "La date est requise"
                        }
                    },
                },
                rdv_heure: {
                    trigger: 'blur change',
                    validators: {
                        callback: {
                            callback: function(value, validator, $field) {
                                // Vérifie si l'heure de début est entre 09:00 et 20:00
                                var startTime = moment(value, 'HH:mm');
                                var minStartTime = moment('08:59', 'HH:mm');
                                var maxStartTime = moment('20:01', 'HH:mm');
                                return startTime.isAfter(minStartTime) && startTime.isBefore(maxStartTime);
                            },
                            message: "L'heure du rdv doit être entre 09:00 et 20:00"
                        },
                        notEmpty: {
                            message: "L'heure du rdv est requise"
                        }
                    }
                },
                salesperson_id: {
                    validators: {
                        notEmpty: {
                            message: "Le commercial est requis"
                        }
                    }
                },
                last_name: {
                    validators: {
                        notEmpty: {
                            message: "Le nom client est requis"
                        }
                    }
                },
                first_name: {
                    validators: {
                        notEmpty: {
                            message: "Le prénom client est requis"
                        }
                    }
                },
                birth_date: {
                    trigger: 'blur',
                    validators: {
                        callback: {
                            message: 'La date de naissance est requise',
                            callback: function(value, validator, $field) {
                                if(document.getElementById('birth_date').value){
                                    return true;
                                }
                                return false;
                            }
                        },
                        date: {
                            format: "DD/MM/YYYY",
                            min:  new Date( (new Date().getYear() - 76), (new Date().getMonth() ), (new Date().getDay()) ),
                            message: "L'age du client est supérieur à 75 ans"
                        }
                    }
                },
                address: {
                    validators: {
                        // notEmpty: {
                        //     message: "L'adresse du client est requis"
                        // }
                        callback: {
                            message: "L'adresse du client est requis",
                            callback: function(value, validator, $field) {
                                if(document.getElementById('address').value ||
                                    jQuery.inArray(document.getElementById('typeEdit').value, ['maj_bs', 'invalid_bs']) > 0){
                                    return true;
                                }
                                return false;
                            }
                        },
                    }
                },
                zip_code: {
                    validators: {
                        notEmpty: {
                            message: "Le code postal du client est requis"
                        },
                        callback: {
                            message: 'Le code postal ne correspond pas au cluster',
                            callback: function(value, validator, $field) {
                                var selectCluster = document.getElementById('child_city_id');
                                var selectedTextClusteur = selectCluster.options[selectCluster.selectedIndex].text;
                                var ZipCode = document.getElementById('zip_code').value;

                                return selectedTextClusteur.trim() === '' || selectedTextClusteur.trim().substring(0, 2) === ZipCode.substring(0, 2);
                            }
                        },
                    }
                },
                child_city_id: {
                    validators: {
                        callback: {
                            message: 'Le cluster ne correspond pas au code postal',
                            callback: function(value, validator, $field) {
                                var selectCluster = document.getElementById('child_city_id');
                                var selectedTextClusteur = selectCluster.options[selectCluster.selectedIndex].text;
                                var ZipCode = document.getElementById('zip_code').value;

                                return selectedTextClusteur.trim() === '' || selectedTextClusteur.trim().substring(0, 2) === ZipCode.substring(0, 2);
                            }
                        },
                    }
                },
                city: {
                    validators: {
                        callback: {
                            message: "Le ville du client est requis",
                            callback: function(value, validator, $field) {
                                if(document.getElementById('city').value ||
                                    (jQuery.inArray(document.getElementById('operator_id').value, ['10', '12']) > 0 && document.getElementById('typeEdit').value != 'prospect_bs') ||
                                    jQuery.inArray(document.getElementById('typeEdit').value, ['maj_bs', 'invalid_bs']) > 0) {
                                    return true;
                                }
                                return false;
                            }
                        },
                    }
                },
                phone_1: {
                    validators: {
                        /*
                        remote: {
                            message: 'Le téléphone existe déjà',
                            url: '/bs/control',
                            data:function(validator, $field, value) {
                                return {
                                    type: 'phone_1',
                                    operator_id: validator.getFieldElements('operator_id').val(),
                                    commande_id: validator.getFieldElements('commande_id').val(),
                                    phone_1: validator.getFieldElements('phone_1').val(),
                                    typeEdit: validator.getFieldElements('typeEdit').val(),
                                    //!number: document.getElementById('number').value
                                    clientId: clientId,
                                };
                            },
                            type: 'GET'
                        },
                        */
                        callback: {
                            message: "Le téléphone client est requis",
                            callback: function(value, validator, $field) {
                                if(document.getElementById('phone_1').value ||
                                  (jQuery.inArray(document.getElementById('operator_id').value, ['10', '12']) > 0 && document.getElementById('typeEdit').value != 'prospect_bs') ||
                                  jQuery.inArray(document.getElementById('typeEdit').value, ['maj_bs', 'invalid_bs']) > 0) {
                                    return true;
                                }
                                return false;
                            }
                        },
                        integer: {
                            message: 'La valeur n\'est pas un chiffre'
                        }
                    }
                },
                phone_2: {
                    validators: {
                        integer: {
                            message: 'La valeur n\'est pas un chiffre'
                        },
                        remote: {
                            message: 'Le téléphone existe déjà',
                            url: '/bs/control',
                            data:function(validator, $field, value) {
                                return {
                                    type: 'phone_1',
                                    operator_id: validator.getFieldElements('operator_id').val(),
                                    commande_id: validator.getFieldElements('commande_id').val(),
                                    phone_1: validator.getFieldElements('phone_2').val(),
                                    typeEdit: validator.getFieldElements('typeEdit').val(),
                                    //!number: document.getElementById('number').value
                                    clientId: form.getAttribute('data-clientId')
                                };
                            },
                            type: 'GET'
                        },
                    }
                },
                phone_3: {
                    validators: {
                        integer: {
                            message: 'La valeur n\'est pas un chiffre'
                        },

                        remote: {
                            message: 'Le téléphone existe déjà',
                            url: '/bs/control',
                            data:function(validator, $field, value) {
                                return {
                                    type: 'phone_1',
                                    operator_id: validator.getFieldElements('operator_id').val(),
                                    commande_id: validator.getFieldElements('commande_id').val(),
                                    phone_1: validator.getFieldElements('phone_3').val(),
                                    typeEdit: validator.getFieldElements('typeEdit').val(),
                                    //!number: document.getElementById('number').value
                                    clientId: form.getAttribute('data-clientId')
                                };
                            },
                            type: 'GET'
                        },
                    }
                },
                email: {
                    validators: {
                        notEmpty: {
                            message: "L'email est requis"
                        },
                        emailAddress: {
                            message: 'La valeur n\'est pas une adresse e-mail valide'
                        },
                        remote: {
                            url: '/bs/checkmail',
                            method: 'GET',
                            data: function() {
                                return {
                                    email: document.getElementById('email').value
                                };
                            },
                            message: 'E-mail existant',
                        }
                    }
                },
                change_rdv_date: {
                    trigger: 'blur change',
                    validators: {
                        date : {
                            format: "DD/MM/YYYY",
                            min: moment().add(1, 'days').format('DD/MM/YYYY'),
                            message: "La date ne peut-être antérieur ou égale à la date du jour"
                        },
                        callback: {
                            callback: function(value, validator, $field) {
                                // Vérifie si la date choisie est un dimanche
                                var selectedDate = moment(value, 'DD/MM/YYYY');
                                return selectedDate.day() !== 0; // 0 correspond à dimanche
                            },
                            message: "La date choisie ne peut pas être un dimanche"
                        },
                        notEmpty: {
                            message: "La date est requise"
                        }
                    },
                },
                change_rdv_heure: {
                    trigger: 'blur change',
                    validators: {
                        callback: {
                            callback: function(value, validator, $field) {
                                // Vérifie si l'heure de début est entre 09:00 et 20:00
                                var startTime = moment(value, 'HH:mm');
                                var minStartTime = moment('08:59', 'HH:mm');
                                var maxStartTime = moment('20:01', 'HH:mm');
                                return startTime.isAfter(minStartTime) && startTime.isBefore(maxStartTime);
                            },
                            message: "L'heure du rdv doit être entre 09:00 et 20:00"
                        },
                        notEmpty: {
                            message: "L'heure du rdv est requise"
                        }
                    }
                },
            }
        });

    }else {
        //if($('#status_id').val() != "29"){

        $('#frmEditBs').formValidation({
            framework: "uikit",
            trigger: 'blur',
            button: {
                selector: '#btnSubmit',
                disabled: 'disabled'
            },
            fields: {
                number: {
                    verbose: false,
                    validators: {
                        notEmpty: {
                            message: "Le numéro BS est requis"
                        },
                        regexp: {
                            message: "Le format du numéro de BS n'est pas accepté.",
                            regexp: /^([A-Z,0-9]{0,6})+([0-9,A-Z]{5,17})$/i
                        },
                        remote: {
                            message: 'Le n° de BS existe déjà',
                            url: '/bs/control',
                            data: {
                                type: 'number',
                                idbs: $('#idbs').val(),
                                typeEdit: $('#typeEdit').val()
                            },
                            type: 'GET'
                        },
                        callback: {
                            message: 'Le numéro BS ne correspond pas au contrat',
                            callback: function(value, validator, $field) {
                                if(document.getElementById('operator_id').value == 12){
                                    if(document.getElementById('commande_id').value == 3 &&
                                        !value.startsWith('VLA')){
                                        return false;
                                    }
                                    var regExpSFR = new RegExp("^([A-Z]{3})+([0-9]{17})$");
                                    var regExpVLA = new RegExp("^([A-Z]{3})+([0-9]{10})$");
                                    if(value.startsWith('VLA') && !regExpVLA.test(value)) {
                                        return false;
                                    }
                                    if((value.startsWith('THD') || value.startsWith('CBL')) && !regExpSFR.test(value)) {
                                        return false;
                                    }
                                }
                                return true;
                            }
                        },
                    }
                },
                operator_id: {
                    validators: {
                        notEmpty: {
                            message: "L'opérateur est requis"
                        }
                    }
                },
                salesperson_id: {
                    validators: {
                        notEmpty: {
                            message: "Le commercial est requis"
                        }
                    }
                },
                energy_id: {
                    validators: {
                        notEmpty: {
                            message: "L'energie est requis"
                        }
                    }
                },
                rib: {
                    validators: {
                        notEmpty: {
                            message: "Indication présence RIB requis"
                        }
                    }
                },
                last_name: {
                    validators: {
                        notEmpty: {
                            message: "Le nom client est requis"
                        }
                    }
                },
                first_name: {
                    validators: {
                        notEmpty: {
                            message: "Le prénom client est requis"
                        }
                    }
                },
                birth_date: {
                    trigger: 'blur',
                    validators: {
                        callback: {
                            message: 'La date de naissance est requise',
                            callback: function(value, validator, $field) {
                                if(document.getElementById('birth_date').value ||
                                    (document.getElementById('operator_id').value == 12 && document.getElementById('typeEdit').value != 'prospect_bs')){
                                    return true;
                                }
                                return false;
                            }
                        },
                        date: {
                            format: "DD/MM/YYYY",
                            min:  new Date((new Date().getYear() - 76), (new Date().getMonth()), (new Date().getDay())),
                            message: "L'age du client est supérieur à 75 ans"
                        }
                    }
                },
                date_fin_engage_gaz: {
                    validators: {
                        date: {
                            format: "DD/MM/YYYY",
                            max: moment().add(6, 'months').format('DD/MM/YYYY'),
                            message: "La date de fin d'engagement est supérieur à 6 mois"
                        }
                    }
                },
                date_fin_engage_elec: {
                    validators: {
                        date: {
                            format: "DD/MM/YYYY",
                            max:  moment().add(6, 'months').format('DD/MM/YYYY'),
                            message: "La date de fin d'engagement est supérieur à 6 mois"
                        }
                    }
                },
                email: {
                    validators: {
                        emailAddress: {
                            message: 'La valeur n\'est pas une adresse e-mail valide'
                        },
                        remote: {
                            url: '/bs/checkmail',
                            method: 'GET',
                            data: function() {
                                return {
                                    email: document.getElementById('email').value,
                                    number: document.getElementById('number').value,
                                    operator_id: document.getElementById('operator_id').value,
                                    commande_id: document.getElementById('commande_id').value,
                                };
                            },
                            message: 'E-mail existant',
                        }
                    }
                },
                address: {
                    validators: {
                        // notEmpty: {
                        //     message: "L'adresse du client est requis"
                        // }
                        callback: {
                            message: "L'adresse du client est requis",
                            callback: function(value, validator, $field) {
                                if(document.getElementById('address').value ||
                                    (document.getElementById('operator_id').value == 12 && document.getElementById('typeEdit').value != 'prospect_bs')  ||
                                    jQuery.inArray(document.getElementById('typeEdit').value, ['maj_bs', 'invalid_bs']) > 0){
                                    return true;
                                }
                                return false;
                            }
                        },
                    }
                },
                operator_id: {
                    validators: {
                        notEmpty: {
                            message: "L'opérateur est requis"
                        },
                    }
                },
                zip_code: {
                    validators: {
                        callback: {
                            message: "Le code postal du client est requis",
                            callback: function(value, validator, $field) {
                                if(document.getElementById('zip_code').value ||
                                    (document.getElementById('operator_id').value != 16 && document.getElementById('typeEdit').value != 'prospect_bs') ||
                                    jQuery.inArray(document.getElementById('typeEdit').value, ['maj_bs', 'invalid_bs']) > 0){
                                    return true;
                                }
                                return false;
                            }
                        }
                    }
                },
                child_city_id: {
                    validators: {
                        callback: {
                            message: 'Le cluster ne correspond pas au code postal',
                            callback: function(value, validator, $field) {
                                var selectCluster = document.getElementById('child_city_id');
                                var selectedTextClusteur = selectCluster.options[selectCluster.selectedIndex].text;
                                var ZipCode = document.getElementById('zip_code').value;

                                return selectedTextClusteur.trim() === '' || selectedTextClusteur.trim().substring(0, 2) === ZipCode.substring(0, 2);
                            }
                        },
                    }
                },
                city: {
                    validators: {
                        callback: {
                            message: "Le ville du client est requis",
                            callback: function(value, validator, $field) {
                                if(document.getElementById('city').value ||
                                    (jQuery.inArray(document.getElementById('operator_id').value, ['10', '12']) > 0 && document.getElementById('typeEdit').value != 'prospect_bs') ||
                                    jQuery.inArray(document.getElementById('typeEdit').value, ['maj_bs', 'invalid_bs']) > 0) {
                                    return true;
                                }
                                return false;
                            }
                        },
                    }
                },
                phone_1: {
                    validators: {
                        // notEmpty: {
                        //     message: "Le téléphone client est requis"
                        // },
                        remote: {
                            message: 'Le téléphone existe déjà',
                            url: '/bs/control',
                            data:function(validator, $field, value) {
                                return {
                                    type: 'phone_1',
                                    operator_id: validator.getFieldElements('operator_id').val(),
                                    commande_id: validator.getFieldElements('commande_id').val(),
                                    phone_1: validator.getFieldElements('phone_1').val(),
                                    typeEdit: validator.getFieldElements('typeEdit').val(),
                                    number: document.getElementById('number').value,
                                    clientId: form.getAttribute('data-clientId')
                                };
                            },
                            type: 'GET'
                        },
                        callback: {
                            message: "Le téléphone client est requis",
                            callback: function(value, validator, $field) {
                                if(document.getElementById('phone_1').value ||
                                  (jQuery.inArray(document.getElementById('operator_id').value, ['10', '12']) > 0 && document.getElementById('typeEdit').value != 'prospect_bs') ||
                                  jQuery.inArray(document.getElementById('typeEdit').value, ['maj_bs', 'invalid_bs']) > 0) {
                                    return true;
                                }
                                return false;
                            }
                        },
                        integer: {
                            message: 'La valeur n\'est pas un chiffre'
                        }
                    }
                },
                phone_2: {
                    validators: {
                        integer: {
                            message: 'La valeur n\'est pas un chiffre'
                        },
                        remote: {
                            message: 'Le téléphone existe déjà',
                            url: '/bs/control',
                            data:function(validator, $field, value) {
                                return {
                                    type: 'phone_1',
                                    operator_id: validator.getFieldElements('operator_id').val(),
                                    commande_id: validator.getFieldElements('commande_id').val(),
                                    phone_1: validator.getFieldElements('phone_2').val(),
                                    typeEdit: validator.getFieldElements('typeEdit').val(),
                                    number: document.getElementById('number').value,
                                    clientId: form.getAttribute('data-clientId')
                                };
                            },
                            type: 'GET'
                        },
                    }
                },
                phone_3: {
                    validators: {
                        integer: {
                            message: 'La valeur n\'est pas un chiffre'
                        },

                        remote: {
                            message: 'Le téléphone existe déjà',
                            url: '/bs/control',
                            data:function(validator, $field, value) {
                                return {
                                    type: 'phone_1',
                                    operator_id: validator.getFieldElements('operator_id').val(),
                                    commande_id: validator.getFieldElements('commande_id').val(),
                                    phone_1: validator.getFieldElements('phone_3').val(),
                                    typeEdit: validator.getFieldElements('typeEdit').val(),
                                    number: document.getElementById('number').value,
                                    clientId: form.getAttribute('data-clientId')
                                };
                            },
                            type: 'GET'
                        },
                    }
                },
                phone_transfer: {
                    validators: {
                        regexp: {
                            message: "Le format du numéro est incorrect.",
                            regexp: /^(\+33|0033|0)(6|7)[0-9]{8}$/g
                        }
                    }
                },
                phone_rio: {
                    validators: {
                        regexp: {
                            message: "Le format du RIO est incorrect.",
                            regexp: /[A-E0-9][A-Z0-9][EP][A-Z0-9]{6}[A-Z0-9+]{3}$/i
                        }
                    }
                },
                car: {
                    validators: {
                        integer: {
                            message: 'La valeur n\'est pas un chiffre'
                        }
                    }
                },
                vendor_index: {
                    validators: {
                        notEmpty: {
                            message: "L'index commercial est requis"
                        }
                    }
                },
                vendor_index_value: {
                    validators: {
                        integer: {
                            message: 'La valeur n\'est pas un chiffre'
                        }
                    }
                },
                abandon_withdrawal_period: {
                    validators: {
                        notEmpty: {
                            message: "Renonciation délai retractation requis"
                        }
                    }
                },
                boiler: {
                    validators: {
                        notEmpty: {
                            message: "Indication chaudière requis"
                        }
                    }
                },
                paiement_mode: {
                    validators: {
                        notEmpty: {
                            message: "Le mode de paiement est requis"
                        }
                    }
                },
                signature_date: {
                    trigger: 'blur change',
                    validators: {
                        notEmpty: {
                            message: "La date de signature est requise"
                        }
                    }
                },
                in_operator_id: {
                    validators: {
                        notEmpty: {
                            message: "L'opérateur antérieur est requis"
                        }
                    }
                },
                siret: {
                    validators: {
                        siret: {
                            message: "Le numéro de siret n'est pas valide"
                        }
                    }
                },
                callcenter_id: {
                    validators: {
                        notEmpty: {
                            message: "L'opérateur de saisie est requis"
                        }
                    }
                }
            }
        });

        //Champ opératateur de saisie obligatoire que pour le call
        if(!$("#callcenter_id").hasClass("be-required")){
            $("#frmEditBs").formValidation('enableFieldValidators', 'callcenter_id', false);
        }
    //}

        $('#frmCompleteBs').formValidation({
            framework: "uikit",
            trigger: 'blur',
            fields: {
                number: {
                    validators: {
                        notEmpty: {
                            message: "Le numéro BS est requis"
                        }
                        ,
                        regexp: {
                            message: "Ex format : PPD1234567.",
                            regexp: /^([A-Z]{3,6})+([0-9]{7})$/i
                        }
                    }
                }
            }
        });


        $('#frmEditUnavailability').formValidation({
            framework: "uikit",
            trigger: 'blur',
            button: {
                selector: '#btnSubmit',
                disabled: 'disabled'
            },
            fields: {
                unavailability_date : {
                    trigger: 'blur change',
                    validators: {
                        date : {
                            format: "DD/MM/YYYY",
                            min: moment().add(7, 'day').format('DD/MM/YYYY'),
                            message: "La date doit être supérieur de 7 jours à la date du jour"
                        },
                        callback: {
                            callback: function(value, validator, $field) {
                                // Vérifie si la date choisie est un dimanche
                                var selectedDate = moment(value, 'DD/MM/YYYY');
                                return selectedDate.day() !== 0; // 0 correspond à dimanche
                            },
                            message: "La date choisie ne peut pas être un dimanche"
                        },
                        notEmpty: {
                            message: "La date est requise"
                        }
                    }
                },
                unavailability_start: {
                    trigger: 'blur change',
                    validators: {
                        callback: {
                            callback: function(value, validator, $field) {
                                // Vérifie si l'heure de début est entre 09:00 et 20:00
                                var startTime = moment(value, 'HH:mm');
                                var minStartTime = moment('08:59', 'HH:mm');
                                var maxStartTime = moment('20:01', 'HH:mm');
                                return startTime.isAfter(minStartTime) && startTime.isBefore(maxStartTime);
                            },
                            message: "L'heure de début doit être entre 09:00 et 20:00"
                        },
                        notEmpty: {
                            message: "L'heure de début est requise"
                        }
                    }
                },
                unavailability_end : {
                    trigger: 'blur change',
                    validators: {
                        callback: {
                            callback: function(value, validator, $field) {
                                // Vérifie si l'heure de fin est entre 09:00 et 20:00 et après l'heure de début
                                var endTime = moment(value, 'HH:mm');
                                var minEndTime = moment('08:59', 'HH:mm');
                                var maxEndTime = moment('20:01', 'HH:mm');
                                return endTime.isAfter(minEndTime) && endTime.isBefore(maxEndTime);
                            },
                            message: "L'heure de fin doit être entre 09:00 et 20:00"
                        },
                        notEmpty: {
                            message: "L'heure de fin est requise"
                        }
                    }
                },
            }
        });





    }

});
